import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from 'gatsby'

const VientoPage = () => (
  <Layout>
    <nav class="navbar">
      <Link class="navbar-brand" to="/">Volver</Link>
    </nav>
    <SEO title="Viento" />
    <img className="img-fluid" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-3.jpg" alt="Arbol" />
    <div className="row justify-content-center">
      <div className="col-12 col-md-8">
        <h1 class="text-center mt-5 mb-4">Acto Viento</h1>
        <p class="text-center">

          En el sendero que va de la comunidad de Xocén a Kanxoc un viento recorre el camino, los árboles van susurrando que se acerca la tarde, las hojas del jabín dejan pasar el soplo. El viento lleva el sonido del monte por donde quiera que pasa, guarda notas
          de antaño que recuerdan los pasos de las guerras, el caracol que convoca a reunión, las trompetas…
                    <br /><br /> Es uno de esos vientos que se ve venir, corpulento y firme.
                </p>
      </div>
    </div>
    <video controls loop autoPlay class="img-fluid mb-5  mt-5" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/choom560.mp4"></video>
    <div className="row justify-content-center">
      <div className="col-12 col-md-8">
        <p class="text-center">

          Hay vientos buenos y malos, los que traen sequía y los que tumban plantas, los hay frescos y suaves, amarillos, verdes y blancos.
                    <br /><br /> Recorren las cuevas y cenotes, la plaza, la milpa.
                    <br /><br /> Del viento también se aprende la palabra del monte, como hablan las cosas. Es un silbido rebelde.
                </p>
      </div>
    </div>

  </Layout>
)

export default VientoPage
